<template>
    <div class="admin-content">
        <h3>메뉴 관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getMenuInfo" >조회</button>
                    <button class="btn-box green" @click="{
                        state.dialog.isOpen=true;
                        state.dialog.isNew=true;
                    }">신규</button>
                </div>
            </div>
        </div>

        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{state.treeTable.rowCount}}</strong></p>
        </div>

        <TreeTable  
            class="table-wrap p-treetable-sm"
            style="margin-bottom: 2rem"
            columnResizeMode="fit"
            selectionMode="multiple"
            :resizableColumns="true"
            :metaKeySelection="false"
            :value="state.treeTable.menuItems"
            @nodeSelect="eventHandler.treeTable.onNodeSelect">
            
            <Column field="MenuID" header="메뉴ID" :style="{'width': '200px'}" :expander="true"></Column>
            <Column field="MenuName" header="메뉴명" :style="{'width': '200px'}"></Column>
            <Column field="SortOrder" header="정렬순서" :style="{'width': '90px'}"></Column>
            <Column field="MenuLevel" header="메뉴레벨" :style="{'width': '90px'}"></Column>
            <Column field="Expanded" header="확장" style="text-align:center; width:90px;"></Column>
            <Column field="MenuHide" header="숨김" style="text-align:center; width:90px;"></Column>
            <Column field="UseYN" header="사용" style="text-align:center; width:90px;"></Column>
            <Column field="ProgramPath" header="경로"></Column>
            <Column field="Params" header="파라미터"></Column>

        </TreeTable>

        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1 col2">
                <div>
                    <label>상위메뉴</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.treeTable.rowDataModel.PrntMenuID" maxlength="12" :disabled="!state.dialog.isNew" />
                    </div>
                </div>
                <div>
                    <label>메뉴ID <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="menuID" type="text" v-model.trim="state.treeTable.rowDataModel.MenuID" :disabled="!state.dialog.isNew" maxlength="12" />
                    </div>
                </div>
                <div>
                    <label>메뉴명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="menuName" type="text" v-model.trim="state.treeTable.rowDataModel.MenuName" maxlength="255" />
                    </div>
                </div>
                <div>
                    <label>정렬순서 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="sortOrder" type="number" v-model.trim="state.treeTable.rowDataModel.SortOrder" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                    </div>
                </div>
                <div>
                    <label>메뉴레벨 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="menuLevel" type="number" v-model.trim="state.treeTable.rowDataModel.MenuLevel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                    </div>
                </div>
                <div>
                    <label>확장 여부</label>
                    <div class="select-box">
                       <select v-model="state.treeTable.rowDataModel.Expanded">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                       </select>
                    </div>
                </div>
                <div>
                    <label>숨김 여부</label>
                    <div class="select-box">
                       <select v-model="state.treeTable.rowDataModel.MenuHide">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                       </select>
                    </div>
                </div>
                <div>
                    <label>사용 여부</label>
                    <div class="select-box">
                       <select v-model="state.treeTable.rowDataModel.UseYN">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                       </select>
                    </div>
                </div>
                <div>
                    <label>경로</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.treeTable.rowDataModel.ProgramPath" maxlength="200" />
                    </div>
                </div>
                <div>
                    <label>파라미터</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.treeTable.rowDataModel.Params" maxlength="200" />
                    </div>
                </div>
                <div>
                    <label>아이콘클래스</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.treeTable.rowDataModel.IconClass" maxlength="50" />
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.menuValidation" >저장</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.menuValidation" >수정</button>
                        <button v-show="!state.dialog.isNew" :visible="!state.dialog.isNew" class="btn-box red" @click="methods.deleteMenuInfo" >삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { menuManage } from '@/modules/system/PCM_SM0200E.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    setup() {
        
        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const menuID = ref(); // 메뉴ID
        const menuName = ref(); // 메뉴명
        const sortOrder = ref(); // 정렬순서
        const menuLevel = ref(); // 메뉴레벨

        // state
        const state = reactive({

            // 트리 테이블 관련
            treeTable: {

                // 메뉴 목록
                menuItems: [],

                // 행 데이터 모델
                rowDataModel: new Object(),

                // 행 개수
                rowCount: 0,

            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "메뉴 관리",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            dropDown: {
                items: new Array(),
                ynchoose: [
                    {'CodeName':'Y', 'CodeValue':'Y'},
                    {'CodeName':'N', 'CodeValue':'N'}
                ]
            },

        });

        // 메서드
        const methods = {

            // 메뉴목록 조회
            getMenuInfo: () => {


                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                // 루트 메뉴 초기화
                state.treeTable.menuItems = []

                menuManage.getMenuInfo().then(menuItems => {

                    // 루트 메뉴 목록
                    const rootMenuItems = menuItems.filter(item => item.MenuLevel === 0);

                    // 하위메뉴 탐색 및 반환
                    const getChildMenuItems = (menuInfo) => {

                        // 대상메뉴에 해당하는 자식메뉴 목록
                        const childrenItems = menuItems.filter(item => item.PrntMenuID === menuInfo.MenuID);
                        
                        // 자식메뉴 목록 결과
                        const childMenuItems = new Array();
                        
                        childrenItems.forEach(childrenItem => {
                        
                            const menuObject = new Object();
                            menuObject.key = childrenItem.MenuID;
                            menuObject.data = childrenItem;
                            menuObject.children = getChildMenuItems(childrenItem);

                            childMenuItems.push(menuObject);
                        });

                        return childMenuItems;
                    }

                    rootMenuItems.forEach(rootMenuInfo => {
                        
                        const menuObject = new Object();
                        menuObject.key = rootMenuInfo.MenuID;
                        menuObject.data = rootMenuInfo;
                        menuObject.children = getChildMenuItems(rootMenuInfo);

                        state.treeTable.menuItems.push(menuObject);
                    });

                    // Total 설정
                    state.treeTable.rowCount = menuItems.length;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 메뉴관리 정보 저장
            saveMenuInfo: () => {

                // TODO: 유효성 검사 로직 추가 필요 :이동엽
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                menuManage.saveMenuInfo(state.treeTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.toast.add();

                        // 목록 재조회
                        methods.getMenuInfo();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 메뉴관리 정보 수정
            updateMenuInfo: () => {
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                menuManage.updateMenuInfo(state.treeTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getMenuInfo();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 메뉴관리 정보 삭제
            deleteMenuInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                menuManage.deleteMenuInfo(state.treeTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.toast.remove();

                        // 목록 재조회
                        methods.getMenuInfo();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 메뉴 정보 유효성검사
            menuValidation: () => {

                let validator = state.treeTable.rowDataModel
                
                // 메뉴ID
                if (!validator.MenuID) {

                    menuID.value.focus()
                    alertManager.alert.warning('메뉴ID를 입력해주세요')
                }
                // 메뉴명
                else if (!validator.MenuName) {

                    menuName.value.focus()
                    alertManager.alert.warning('메뉴명을 입력해주세요')
                }
                // 정렬순서
                else if (validator.SortOrder === undefined || validator.SortOrder === '') {

                    sortOrder.value.focus()
                    alertManager.alert.warning('정렬순서를 입력해주세요')
                }
                // 메뉴레벨
                else if (validator.MenuLevel === undefined || validator.MenuLevel === '') {

                    menuLevel.value.focus()
                    alertManager.alert.warning('메뉴레벨을 입력해주세요')
                }
                else {

                    // 메뉴 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveMenuInfo() })
                    else alertManager.confirm.edit().then(() => { methods.updateMenuInfo() })
                }
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            treeTable: {

                onNodeSelect: event => {

                    // 행 데이터 모델 설정
                    state.treeTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {
                    
                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        //신규 버튼 클릭시 드롭다운 리스트 디폴트값 설정
                        state.treeTable.rowDataModel.Expanded = 'N';
                        state.treeTable.rowDataModel.MenuHide = 'N';
                        state.treeTable.rowDataModel.UseYN = 'Y';
                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.treeTable.rowDataModel = new Object();
                    state.dialog.isNew = false;
                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 메뉴관리 초기조회
            methods.getMenuInfo()
        });

        return {
            state,
            methods,
            eventHandler,
            menuID,
            menuName,
            sortOrder,
            menuLevel,
        }
    }
}
</script>